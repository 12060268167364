<template>
  <div class="chart-content">
    <div class="note">
      <a-button v-if="showTooltip" type="link" @click="visible = true"
        ><i class="el-icon-question"></i>分析说明</a-button
      >
    </div>
    <a-modal
      width="45%"
      title="四分位数-箱线图 说明"
      :visible="visible"
      :footer="null"
      @ok="visible = false"
      @cancel="visible = false"
    >
      <div class="plot-box-container">
        <div class="title">名词解释：</div>
        <div class="text">
          四分位数（Quartile）是统计学中分位数的一种，即把所有数值由小到大排列并分成四等份，处于三个分割点位置的数值就是四分位数。
        </div>
        <div class="text">
          箱线图（Boxplot）也称箱须图（Box-whisker
          Plot），它是用一组数据中的最小值、第一四分位数、中位数、第三四分位数和最大值来反映数据分布的中心位置和散布范围，可以粗略地看出数据是否具有对称性。通过将多组数据的箱线图画在同一坐标上，可以用于多组数据平均水平和变异程度的直观分析比较。
        </div>
        <img
          src="@/assets/imgs/academic/sifen1.png"
          style="width: 100%; margin-bottom: 18px"
          alt=""
        />
        <div class="text">
          在箱线图中，箱子的中间有一条线，代表了数据的中位数。箱子的上下底，分别是数据的上四分位数（Q3）和下四分位数（Q1），这意味着箱体包含了50%的数据。因此，箱子的高度在一定程度上反映了数据的波动程度。上下边缘则代表了该组数据的最大值和最小值。有时候箱子外部会有一些点，可以理解为数据中的“异常值”
        </div>
        <div class="title">图像解读：</div>
        <div class="text">01 直观明了地识别数据批中的异常值</div>
        <div class="text">
          箱形图可以用来观察数据整体的分布情况，利用Q2 中位数，Q1 25/%分位数，Q3
          75/%分位数，上边界，下边界等统计量来来描述数据的整体分布情况。通过计算这些统计量，生成一个箱体图，箱体包含了大部分的正常数据，而在箱体上边界和下边界之外的，就是异常数据。
        </div>
        <div class="text">02 判断数据的偏态和尾重</div>
        <div class="text">
          对于标准正态分布的大样本，中位数位于上下四分位数的中央，箱形图的方盒关于中位线对称。中位数越偏离上下四分位数的中心位置，分布偏态性越强。异常值集中在较大值一侧，则分布呈现右偏态；异常值集中在较小值一侧，则分布呈现左偏态
        </div>
        <div class="title">03 比较多批数据的形状</div>
        <div class="text">
          箱子的上下限，分别是数据的上四分位数和下四分位数。这意味着箱子包含了50%的数据。因此，箱子的宽度在一定程度上反映了数据的波动程度。箱体越扁说明数据越集中，端线（也就是“须”）越短也说明数据集中。
        </div>
        <div class="title">实例解读：</div>
        <img
          src="@/assets/imgs/academic/sifen2.png"
          style="width: 100%; margin-bottom: 18px"
          alt=""
        />
        <div class="text">
          上图中我们可以看到学生的英语成绩相对其它科目普遍较好，而数学成绩较为集中，且大部分都处于80分以下。历史与英语中位数比较接近，但是历史成绩分布分散，且成绩主要在90以下。
        </div>
        <div class="title">异常情况描述：</div>
        <div class="text">
          <div>
            箱子被压得很扁，甚至只剩下一条线，同时还存在着很多异常值。这些情况的出现，有两个常见原因。
          </div>
          <div>
            第一，样本数据中，存在特别大或者特别小的异常值，这种离群的表现，导致箱子整体被压缩，反而凸显出来这些异常；
          </div>
          <div>第二，样本数据特别少，因此箱体受单个数据的影响被放大了。</div>
        </div>
      </div>
    </a-modal>
    <div ref="chart" class="chart"></div>
  </div>
</template>
<script>
// 盒须图
import * as echarts from "echarts";
export default {
  props: {
    defineOptions: {
      type: Object,
      default: () => {},
    },
    source: {
      default: () => [],
      type: [Object, Array],
    },
    xAxis: {
      default: () => [],
      type: Array,
    },
    showTooltip: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      options: {
        legend: {
          data: [
            { name: "物理类最高分" },
            { name: "物理类最低分" },
            { name: "物理类中位数" },
          ],
        },
        dataset: [
          {
            source: [
              [
                850, 740, 900, 1070, 930, 850, 950, 980, 980, 880, 1000, 980,
                930, 650, 760, 810, 1000, 1000, 960, 960,
              ],
              [
                960, 940, 960, 940, 880, 800, 850, 880, 900, 840, 830, 790, 810,
                880, 880, 830, 800, 790, 760, 800,
              ],
              [
                880, 880, 880, 860, 720, 720, 620, 860, 970, 950, 880, 910, 850,
                870, 840, 840, 850, 840, 840, 840,
              ],
              [
                890, 810, 810, 820, 800, 770, 760, 740, 750, 760, 910, 920, 890,
                860, 880, 720, 840, 850, 850, 780,
              ],
              [
                890, 810, 810, 820, 800, 770, 760, 740, 750, 760, 910, 920, 890,
                860, 880, 720, 840, 850, 850, 780,
              ],
              [
                890, 810, 810, 820, 800, 770, 760, 740, 750, 760, 910, 920, 890,
                860, 880, 720, 840, 850, 850, 780,
              ],
              [
                890, 840, 780, 810, 760, 810, 790, 810, 820, 850, 870, 870, 810,
                740, 810, 940, 950, 800, 810, 870,
              ],
            ],
          },
          {
            transform: {
              type: "boxplot",
              config: {},
            },
          },
          {
            fromDatasetIndex: 1,
            fromTransformResult: 1,
          },
        ],
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: ["物化生", "物地生", "物地化", "物政地", "物政化", "物政生"],
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "boxplot",
            type: "boxplot",
            datasetIndex: 1,
            itemStyle: {
              color: "#2474ED",
            },
            boxWidth: [20, 20],
          },
          {
            name: "outlier",
            type: "scatter",
            datasetIndex: 2,
            itemStyle: {
              color: "#4589EF",
            },
            symbolSize: 4,
          },
          {
            type: "line",
            name: "物理类最高分",
            symbol: "none",
            lineStyle: {
              type: "dashed",
            },
            color: "#FF0000",
            // color:'#31B400'
            data: [],
          },
          {
            type: "line",
            name: "物理类最低分",
            symbol: "none",
            lineStyle: {
              type: "dashed",
            },
            data: [],
            color: "#ED6024",
            // color:'#2D49FF'
          },
          {
            type: "line",
            name: "物理类中位数",
            symbol: "none",
            lineStyle: {
              type: "dashed",
            },
            data: [],
            color: "#FFAB00",
            // color:'#00CBC7'
          },
        ],
      },
      visible: false,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let options = { ...this.options, ...this.defineOptions };
      options.xAxis.data = this.xAxis;
      options.dataset[1].transform.config.itemNameFormatter = ({ value }) => {
        return this.xAxis[value];
      };
      var chart = echarts.init(this.$refs.chart);
      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-content {
  margin-bottom: 18px;
  flex: 1;
  .note {
    text-align: right;
    height: 32px;
    width: 100%;
    .cursor {
      color: #2474ed;
      i {
        color: #2474ed;
      }
    }
  }
  .chart {
    width: 100%;
    height: 380px;
  }
}
.plot-box-container {
  height: 50vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
  }

  ///*<!--修改 滑块 -->*/
  // border: 1px solid #DEE1E7;
  &::-webkit-scrollbar-thumb {
    background-color: #dee1e7;
  }
  .title {
    color: #2474ed;
    font-size: 14px;
    margin-bottom: 18px;
  }
  .text {
    font-weight: 400;
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
  }
}
</style>
